import React from 'react';
import logo from "../../assets/img/logo/joker-logo.webp"

const SplashScreen = () => {
  return (
    <section>
      <div className="container">
        <div
          className="
          d-flex 
          flex-column 
          justify-content-center 
          align-items-center
          "
          style={{ height: "100vh" }}
        >
          <div className='wow bounceInDown'>
            <img src={logo} alt="logo" width={300} />
          </div>
          <h3 className='pt-4'>Joker AI</h3>
          <h5 className='pt-2'>Where Memes Meet AI</h5>
        </div>
      </div>
    </section>
  )
}

export default SplashScreen;