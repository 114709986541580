import React from "react";
import Banner from "../../components/Banner/Banner";
import ContactOne from "../../components/Contact/ContactOne";
import Roadmap from "../../components/Roadmap/Roadmap";
import Sales from "../../components/Sales/Sales";
import TeamOne from "../../components/Team/TeamOne";
import TopPartners from "../../components/TopPartners/TopPartners";
import WhitePaper from "../../components/WhitePaper/WhitePaper";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import LayoutOne from "../../layouts/LayoutOne";
// import Burning from "../../components/Burning/Burning";
import HowToBuy from "../../components/HowToBuy/HowToBuy";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import SplashScreen from "../../components/SplashScreen/SplashScreen";

const Home = () => {
  return (
    <LayoutOne>
      <main className="fix">
        <div className="banner-bg">
          <Banner />
          <WhoWeAre />
        </div>

        {/* <TopPartners /> */}
        <WhyChooseUs />
        <Sales />
        {/*<Burning />*/}
        <HowToBuy />

        {/* <div className="area-bg"> */}
        {/* <Roadmap /> */}
        <RoadmapTwo />
        {/* </div> */}
        {/* <WhitePaper /> */}

        <TeamOne />
        {/* <ContactOne /> */}
      </main>
    </LayoutOne>
  );
};

export default Home;
