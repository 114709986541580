import React from 'react';
import Chart from 'react-apexcharts';

const PieChart = () => {
  const chartOptions = {
    labels: ['Liquidity', 'Team'],
    legend: {
      show: false
    },
    fill: {
      type: 'gradient',
    },
    stroke: {
      colors: ['#000']
    },

  };
  const series = [90, 10];

  return (
    <div className="pie-chart">
      <Chart options={chartOptions} series={series} type="donut" />
    </div>
  );
};

export default PieChart;
