import React, { useEffect, useState } from "react";
import icon01 from "../../assets/img/icon/choose_icon01.svg";
import icon02 from "../../assets/img/icon/choose_icon02.svg";
import icon03 from "../../assets/img/icon/choose_icon03.svg";
import icon04 from "../../assets/img/icon/choose_icon04.svg";
import WhyChooseUsItem from "./WhyChooseUsItem";

const WhyChooseUs = () => {
  const slider_items = [
    {
      src: icon01,
      alt: "",
      link: "/",
      title: "AI Bug detection & fixing",
      description: "Implementing rigorous testing protocols aids in AI bug detection",
    },
    {
      src: icon02,
      alt: "",
      link: "/",
      title: "Integration of AI & Blockchain",
      description: "Integrating AI with blockchain enhances efficiency and security",
    },
    {
      src: icon03,
      alt: "",
      link: "/",
      title: "Melodic Magic",
      description: "Create music using $JAI AI music generator",
    },
    {
      src: icon04,
      alt: "",
      link: "/",
      title: "Image & Video Wizardry",
      description: "Create Images and Social Media Videos/ads with ease using $JAI AI media Generators",
    },
    {
      src: icon01,
      alt: "",
      link: "/",
      title: "Game Haven",
      description: "$JAI AI GameiFi (Play 2 Earn Games)",
    },
    {
      src: icon03,
      alt: "",
      link: "/",
      title: "NFT Forge",
      description: "NFT Generation module to create customized NFTs using $JAI AI",
    },
    {
      src: icon01,
      alt: "",
      link: "/",
      title: "Reward Harvest",
      description: "$JAI AI Staking Rewards",
    },
    {
      src: icon04,
      alt: "",
      link: "/",
      title: "Data Vault",
      description: "Data monetization and storage",
    },
  ];

  return (
    <section id="upcoming-projects" className="choose-area pb-130 pt-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-50">
              <span className="sub-title">Upcoming Projects</span>
              <h2 className="title">
                Why choose $JAI <span>Token</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          {slider_items.map((item, index) => (
            <div key={index} className="col-lg-4 col-md-6 ">
              <WhyChooseUsItem item={item} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
