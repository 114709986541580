import React from "react";
import img01 from "../../assets/img/images/j-char.png";
import img02 from "../../assets/img/images/about_img02.png";
import { Link } from "react-router-dom";

const WhoWeAre = (props) => {
  return (
    <section id="story" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          {/* <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" width={400} style={{ borderRadius: "50%" }} />
              <img src={img02} alt="" className="img-two" />
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">Who is Joker</span>
                <h2 className="title">
                  Joker: The Story
                </h2>
              </div>
              <p>
                In the fast-paced and ever-evolving world of blockchain technology, a new and mischievous player emerged, ready to disrupt the scene with a blend of humor and innovation. This was the Joker AI, and its stage was the Solana blockchain, known for its lightning-fast transactions and low fees. What started as a quirky experiment soon turned into a legendary tale that captivated the crypto community. <br /><br />
                In a dimly lit basement, a group of visionary developers, who had grown weary of the traditional and often predictable world of cryptocurrencies, sought to create something truly unique. They chose Solana as their platform, impressed by its high throughput and scalability. Combining their love for memes, AI, and the chaotic charm of the Joker, they birthed the Joker AI Coin. <br /><br />

                The coin's initial launch was anything but ordinary. It was announced through a series of cryptic tweets and viral memes, sparking curiosity and intrigue. Early adopters were drawn in by the promise of unpredictable rewards and a community driven by laughter and creativity. <br /><br />

                The Joker’s Secret<br /><br />
                Behind the scenes, the developers had a grander vision. The AI driving the Joker AI Meme Coin was designed to be more than just a prankster. It was a teacher. Each puzzle and prank carried a lesson about the intricacies of blockchain technology, the importance of security, and the potential pitfalls of investing in cryptocurrencies. The community, while entertained, was also becoming more educated and resilient. <br /><br />

                Speculation about the creators' identities ran rampant. Some believed it was a lone genius, while others thought it was a collective effort. There were even rumors that the AI itself had become self-aware and was orchestrating its own antics. The mystery only added to the allure of the Joker AI Meme Coin.
              </p>
              {/* <Link to="/" className="btn">
                Purchase Tokens
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
