import React from "react";
import RoadmapTwoItem from "./RoadmapTwoItem";

const RoadmapTwo = () => {
  const roadmap_items = [
    {
      roadmapTitle: "Stage 1",
      title: "Stage 1",
      info: (
        <>
          {" "}
          1. Strategic Partnership <br />
          2. Private Presale <br />
          3. Marketing Campaigns <br />
          4. Deploy Website <br />
          5. Deploy Contract <br />
          6. Radyium Listing <br />
          7. Renounce Ownership <br />
          8. Liquidity Burning <br />
        </>
      ),
    },
    {
      roadmapTitle: "Stage 2",
      title: "Stage 2",
      info: (
        <>
          {" "}
          1. Press Release <br />
          2. Smart Contract Audits <br />
          3. AI Image and Video Generator(Beta) <br />
          4. Media Promotion  <br />
          5. CG and CMC Listing  <br />
          6. Website Upgrade  <br />
          7. NFT Giveaway for Holders  <br />
        </>
      ),
    },
    {
      roadmapTitle: "Stage 3",
      title: "Stage 3",
      info: (
        <>
          {" "}
          1. CEX Listing <br />
          2. AI NFT Creation Module <br />
          3. AMA with Influencers <br />
          4. Staking and Rewards <br />
          5. Airdrops for Staking <br />
        </>
      ),
    },
    {
      roadmapTitle: "Stage 4",
      title: "Stage 4",
      info: (
        <>
          {" "}
          1. Strategic Partnerships <br />
          2. AI Music Gen <br />
          3. Advertisement Campaigns <br />
        </>
      ),
    },
  ];

  return (
    <section id="roadmap" className="team-area roadmap-area-two pt-130 pb-100">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">OUr Roadmap</span>
              <h2 className="title">
                $JAI Strategy and <br /> Project <span>Plan</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            <div className="roadmap-wrap-two">
              {roadmap_items.map((x, index) => (
                <RoadmapTwoItem key={index} item={x} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoadmapTwo;
