import React from "react";

const RoadmapTwoItem = (props) => {
  return (
    <div className="roadmap-item wow fadeInLeft">
      <span className="roadmap-title">{props.item.roadmapTitle}</span>
      <div className="roadmap-content">
        <span className="dot"></span>
        {/* <h4 className="title" style={{ color: "#fefefe" }}>{props.item.title}</h4> */}
        <div style={{ color: "#fefefe", fontSize: "18px" }}>{props.item.info}</div>
      </div>
    </div>
  );
};

export default RoadmapTwoItem;
