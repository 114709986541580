import React, { useState } from "react";
import FooterOne from "../components/Footer/FooterOne";
import HeaderOne from "../components/Header/HeaderOne";
import PageLoader from "../components/PageLoader/PageLoader";
import cn from "classnames";
import SplashScreen from "../components/SplashScreen/SplashScreen";

const LayoutOne = (props) => {
  const [isSplash, setIsSplash] = useState(true);

  setTimeout(() => {
    setIsSplash(false);
  }, 3000);

  return (
    <>
      {isSplash ? (
        <SplashScreen />
      ) : (
        <div className={cn("")}>
          {/* <PageLoader /> */}
          <HeaderOne />
          {props.children}
          <FooterOne />
        </div >
      )}
    </>
  );



};

export default LayoutOne;
