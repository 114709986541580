import React from "react";
import shape01 from "../../assets/img/banner/banner_shape01.png";
import shape02 from "../../assets/img/banner/banner_shape02.png";
import shape03 from "../../assets/img/banner/banner_shape03.png";
import fireIcon from "../../assets/img/icon/fire.png";
import { Link } from "react-router-dom";
import CountDownOne from "../CountDown/CountDownOne";

const Banner = (props) => {
  return (
    <section className="banner-area banner-bg-mb">
      <div className="banner-shape-wrap">
        <img src={shape01} alt="" className="img-one" />
        <img src={shape02} alt="" className="img-two" />
        <img src={shape03} alt="" className="img-three" />
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              {/* <img src={fireIcon} alt="" /> */}
              <h3>Meet Joker ($JAI)</h3>
              <h2 className="title">
                The most evil token on Solana Blockchain
              </h2>
              <h3 className="pb-2">"Where memes meet AI"</h3>
            </div>
          </div>
        </div>

        <div className="container" style={{ padding: "20px 0 50px 0", maxWidth: "436px" }}>
          <div className="row" >
            <div className="d-flex justify-content-center pb-4">
              <h6>Here you can buy JAI</h6>
            </div>
            <div
              className="col-md-6"
              style={{ paddingBottom: "10px" }}
            >
              <div className={"d-flex justify-content-center header-btn "}>
                <Link to="https://raydium.io/swap/?inputMint=sol&outputMint=4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R" className="btn" target="_blank">
                  Radyium
                </Link>
              </div>
            </div>

            <div className="col-md-6">
              <div className={"d-flex justify-content-center header-btn"}>
                <Link to="https://dexscreener.com/" className="btn" target="_blank">
                  Dexscreener
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="banner-countdown-wrap text-center">
              <h2 className="title">JAI Will Start In..</h2>

              <CountDownOne />
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Banner;
