import React from 'react';
import jklogo from "../../assets/img/logo/joker-logo.webp";

import phantom from "../../assets/img/howtobuy/phantom.webp"
import solana from "../../assets/img/howtobuy/solana.webp"
import jup from "../../assets/img/howtobuy/jup.webp"

const steps = [
  {
    id: 1,
    title: "Create a Wallet",
    description: "Get the Phantom wallet from the app store, Google Play Store, or as a Chrome extension at phantom.app.",
    logo: phantom,
  },
  {
    id: 2,
    title: "Get Some SOL",
    description: "Ensure you have SOL in your wallet to swap for $JAI. You can buy SOL directly in Phantom, transfer it from another wallet, or purchase it on an exchange. SOL can also be bought with a credit card through the Phantom app.",
    logo: solana,
  },
  {
    id: 3,
    title: "Head to Jupiter",
    description: "Visit Jup.ag in Chrome or open up the Phantom app on your phone. Connect your wallet, enter JAI in the \"To receive\" section, select JAI. Now enter in how much SOL you want to spend in the \"You're paying\" section.",
    logo: jup,
  },
  {
    id: 4,
    title: "Swap SOL for $JAI",
    description: "Swap your SOL for $JAI by clicking the Swap button and confirming the transaction with your Phantom wallet. If the transaction fails simply try again or consider increasing your slippage to 1% if the market is volatile.",
    logo: jklogo,
  },
];

const HowToBuy = () => {
  return (
    <section id="howtobuy" style={{ paddingTop: "50px" }}>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-50">
              <span className="sub-title">Buy $JAI</span>
              <h2 className="title">
                How to buy $JAI
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          {steps.map(step => (
            <div
              key={step.id}
              className="col-md-12 mb-4 wow fadeInUp"
            >
              <div className="card htb-card">
                <div className="card-body d-flex align-items-center htb-card-body">
                  <div>
                    <img
                      src={step.logo}
                      alt={`${step.title} logo`}
                      width={100}
                      className="htb-card-img"
                    />
                  </div>
                  <div className='d-flex flex-column'>
                    <div className="d-flex align-items-center mb-3">
                      <h4 className="card-title mb-1" >
                        {step.id}. {step.title}
                      </h4>
                    </div>
                    <div>
                      <p className="htb-card-p">
                        {step.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section >
  );
}

export default HowToBuy;
