import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import logoImage from "../../../src/assets/img/logo/logo.png";
import $ from "jquery";
import { handleClickScroll } from "../../lib/helpers";
import logo from "../../assets/img/logo/joker-logo.webp"
import Socials from "../socials/Socials";

const HeaderOne = () => {
  // sticky nav bar
  const [stickyClass, setStickyClass] = useState({
    fixed: "",
    header: "",
  });

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight < 245
        ? setStickyClass({ fixed: "", header: "" })
        : setStickyClass({ fixed: "active-height", header: "sticky-menu" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  // mobile menu
  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      let mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });
    }
  }, []);

  // active link switching
  const { hash, pathname } = useLocation();
  const isActiveLink = (id) => {
    return id == hash ? "active" : "";
  };

  return (
    <header id="header">
      <div id="header-fixed-height" className={cn(stickyClass.fixed)} />

      <div id="sticky-header" className={cn("menu-area", stickyClass.header)}>
        <div className={cn("container custom-container")}>
          <div className="row">
            <div className="col-12">
              <div className={"mobile-nav-toggler"}>
                <i className="fas fa-bars" />
              </div>

              <div className="menu-wrap">
                <nav className={"menu-nav"}>
                  <div className="logo " style={{ fontSize: "35px", fontWeight: 700 }}>
                    {/* <Link to={"/#header"}>
                      <img src={logo} alt="" width={80} />
                    </Link> */}

                    <Link
                      to="#header"
                      className={"section-link"}
                      onClick={() => handleClickScroll("header")}
                    >
                      <img src={logo} alt="" width={80} />
                    </Link>
                  </div>

                  <div className={cn("navbar-wrap main-menu d-none d-lg-flex")}>
                    <ul className={"navigation"}>
                      <li
                        className={cn(
                          hash == "" && "active",
                          "menu-item-has-children"
                        )}
                      >
                        <Link
                          to="#header"
                          className={"section-link"}
                          onClick={() => handleClickScroll("header")}
                        >
                          Home
                        </Link>
                        {/* <ul className={cn("sub-menu")}>
                          <li className={cn(pathname == "/" && "active")}>
                            <NavLink to="/">Home One</NavLink>
                          </li>
                          <li
                            className={cn(pathname == "/home-two" && "active")}
                          >
                            <NavLink to="/home-two">Home Two</NavLink>
                          </li>
                        </ul> */}
                      </li>
                      <li className={cn(hash === "#story" && "active")}>
                        <Link
                          to="#story"
                          className={"section-link"}
                          onClick={() => handleClickScroll("story")}
                        >
                          Story
                        </Link>
                      </li>
                      {/* <li className={cn(hash === "#partners" && "active")}>
                        <Link
                          to="#partners"
                          className={"section-link"}
                          onClick={() => handleClickScroll("partners")}
                        >
                          Partners
                        </Link>
                      </li> */}
                      <li className={cn(hash === "#upcoming-projects" && "active")}>
                        <Link
                          to="#upcoming-projects"
                          className={"section-link"}
                          onClick={() => handleClickScroll("upcoming-projects")}
                        >
                          Upcoming Projects
                        </Link>
                      </li>
                      <li className={isActiveLink("#tokenomics")}>
                        <Link
                          to="#tokenomics"
                          className={"section-link"}
                          onClick={() => handleClickScroll("tokenomics")}
                        >
                          Tokenomics
                        </Link>
                      </li>
                      {/*<li className={isActiveLink("#burning")}>*/}
                      {/*  <Link*/}
                      {/*    to="#burning"*/}
                      {/*    className={"section-link"}*/}
                      {/*    onClick={() => handleClickScroll("burning")}*/}
                      {/*  >*/}
                      {/*    Burning*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                      <li className={isActiveLink("#howtobuy")}>
                        <Link
                          to="#howtobuy"
                          className={"section-link"}
                          onClick={() => handleClickScroll("howtobuy")}
                        >
                          How to buy
                        </Link>
                      </li>
                      <li className={isActiveLink("#roadmap")}>
                        <Link
                          to="#roadmap"
                          className={"section-link"}
                          onClick={() => handleClickScroll("roadmap")}
                        >
                          Roadmap 
                        </Link>
                      </li>
                      <li className={isActiveLink("#about-us")}>
                        <Link
                          to="#about-us"
                          className={"section-link"}
                          onClick={() => handleClickScroll("about-us")}
                        >
                          About Us
                        </Link>
                      </li>
                      {/* <li className={isActiveLink("#whitepaper")}>
                        <Link
                          to="#whitepaper"
                          className={"section-link"}
                          onClick={() => handleClickScroll("whitepaper")}
                        >
                          Whitepaper
                        </Link>
                      </li> */}
                      {/* <li className={"menu-item-has-children"}>
                        <Link to="/blog">Blog</Link>
                        <ul className={cn("sub-menu")}>
                          <li className={cn(pathname == "/blog" && "active")}>
                            <Link to="/blog">Our Blog</Link>
                          </li>
                          <li
                            className={cn(
                              pathname == "/blog-details" && "active"
                            )}
                          >
                            <Link to="/blog-details">Blog Details</Link>
                          </li>
                        </ul>
                      </li> */}
                    </ul>
                  </div>
                  <div className={cn("header-action", "d-none d-md-block")}>
                    <ul>
                      {/* <li className={"header-lang"}>
                        <span className={"selected-lang"}>ENG</span>
                        <ul className={"lang-list"}>
                          <li>
                            <Link to="#">IND</Link>
                          </li>
                          <li>
                            <Link to="#">BNG</Link>
                          </li>
                          <li>
                            <Link to="#">TUR</Link>
                          </li>
                          <li>
                            <Link to="#">CIN</Link>
                          </li>
                        </ul>
                      </li> */}
                      {/* <li className={"header-btn"}>
                        <Link to="#" className="btn">
                          Buy Now
                        </Link>
                      </li> */}

                      <li className={"header-lang"}>
                        <span className={"selected-lang"}>Buy Now</span>
                        <ul className={"lang-list"}>
                          <li>
                            <Link to="https://raydium.io/swap/?inputMint=sol&outputMint=4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R" className="btn" target="_blank">
                              Radyium
                            </Link>
                          </li>
                          <li className="pt-1">
                            <Link to="https://dexscreener.com/" className="btn" target="_blank">
                              Dexscreener
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              {/* <!-- Mobile Menu  --> */}
              <div className={"mobile-menu"}>
                <nav className={"menu-box"}>
                  <div className={"close-btn"}>
                    <i className="fas fa-times"></i>
                  </div>
                  <div className={"nav-logo"}>
                    <Link to="/">
                      <img src={logo} alt="" title="" />
                    </Link>
                  </div>

                  <div className={"menu-outer"}>
                    {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->  */}
                  </div>

                  <Socials />
                </nav>
              </div>

              <div className={"menu-backdrop"} />
              {/* <!-- End Mobile Menu --> */}
            </div>
          </div>
        </div>
      </div >
    </header >
  );
};

export default HeaderOne;
